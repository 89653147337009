import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createLocalStorageMiddleware } from './localStorageMiddleware';
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import { childDetailsOptionalSchema } from '../schemas/childDetailsSchema';
import { occasionOptionalSchema } from '../schemas/occasionSchema';
import { themeSchema } from '../schemas/themeSchema';
import { dedicationMessageSchema } from '../schemas/dedicationMessageSchema';
import { personalisationNoteSchema } from '../schemas/personalisationNoteSchema';
import { customerDetailsOptionalSchema } from '../schemas/customerDetailsSchema';
import { similarityOptionalSchema } from '../schemas/similaritySchema';
import { cartOptionalSchema } from '../schemas/cartSchema';
import {
  UploadedImage,
  uploadedImagesSchema,
} from '../schemas/uploadedImageSchema';

export const orderFormDataOptionalSchema = childDetailsOptionalSchema
  .merge(occasionOptionalSchema)
  .merge(themeSchema)
  .merge(personalisationNoteSchema)
  .merge(dedicationMessageSchema)
  .merge(customerDetailsOptionalSchema)
  .merge(similarityOptionalSchema)
  .merge(cartOptionalSchema);

export type OrderFormDataOptional = z.infer<typeof orderFormDataOptionalSchema>;

interface GlobalStore {
  deviceId: string;
  setDeviceId: (deviceId: string) => void;
  images: UploadedImage[];
  order_reference_id: string;
  setOrderReferenceId: (order_reference_id: string) => void;
  addImages: (images: UploadedImage[]) => void;
  setImages: (images: UploadedImage[]) => void;
  uploadingImages: string[];
  setUploadingImages: (urls: string[]) => void;
  removeImage: (image: UploadedImage) => void;
  formData: OrderFormDataOptional;
  getFormData: <T>(schema: z.Schema<T>) => T | undefined;
  saveFormData: (data: OrderFormDataOptional) => void;
  lastUpdate: number;
  resetStore: () => void;
}

const initialOrderFormState = () => {
  const order_reference_id = uuidv4();
  Sentry.setTag('order_reference_id', order_reference_id);

  return {
    deviceId: '',
    images: [],
    uploadingImages: [],
    order_reference_id,
    formData: {},
    lastUpdate: Date.now(),
  };
};

const orderFormStateSchema = z.object({
  deviceId: z.string(),
  order_reference_id: z.string(),
  images: uploadedImagesSchema,
  formData: orderFormDataOptionalSchema,
  lastUpdate: z.number(),
});

const loadInitialState = () => {
  try {
    return {
      ...initialOrderFormState(),
      ...orderFormStateSchema.parse(
        JSON.parse(localStorage.getItem('br_order_form') ?? ''),
      ),
    };
  } catch {
    return initialOrderFormState();
  }
};

export const useGlobalStore = create<GlobalStore>()(
  devtools(
    createLocalStorageMiddleware('br_order_form')((set, get) => ({
      ...loadInitialState(),
      setOrderReferenceId: (order_reference_id) => {
        set(() => ({ order_reference_id }));
        Sentry.setTag('order_reference_id', order_reference_id);
      },
      setDeviceId: (deviceId) => set(() => ({ deviceId })),
      addImages: (images) =>
        set((state) => ({
          images: state.images.concat(images),
        })),
      setImages: (images) => set(() => ({ images: images })),
      setUploadingImages: (uploadingImages) => set(() => ({ uploadingImages })),
      removeImage: (image) =>
        set((state) => ({
          images: state.images.filter((img) => img.s3_path !== image.s3_path),
        })),
      saveFormData: (update) =>
        set((state) => ({
          formData: { ...state.formData, ...update },
          lastUpdate: Date.now(),
        })),
      getFormData: (schema) => schema.safeParse(get().formData).data,
      resetStore: () => set(() => ({ ...initialOrderFormState() })),
    })),
  ),
);

export const setOpenedCheckout = () => {
  localStorage.setItem('br_opened_checkout', 'true');
};

export const isOpenedCheckout = () => {
  return localStorage.getItem('br_opened_checkout') === 'true';
};

export const removeOpenedCheckout = () => {
  localStorage.removeItem('br_opened_checkout');
};
