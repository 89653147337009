import { fetchLatestState } from '@/lib/hooks/fetchLatestState';
import { queryKeys } from '@/lib/hooks/queryKeys';
import { useGlobalStore } from '@/lib/store/global';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getStepNumber, STEPS } from '../config/steps';
import { getStepRoute } from '../utils/useStepNavigation';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useResetIfUserHasPaid = () => {
  const { order_reference_id, resetStore } = useGlobalStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const forwardPath = searchParams.get('forward');

  useEffect(() => {
    const checkStatus = async () => {
      try {
        // Ignore the CancelledError due to ReactStrictMode double-rendering in development mode
        queryClient.resetQueries({
          queryKey: [queryKeys.GET_FORM_INFO],
        });
        const formData = await queryClient.fetchQuery({
          queryKey: [queryKeys.GET_FORM_INFO],
          queryFn: () => fetchLatestState(order_reference_id),
          retry: false,
        });
        if (formData.status !== undefined) {
          resetStore();
          navigate(getStepRoute(getStepNumber(STEPS.STEP_0)));
        } else {
          navigate(forwardPath ?? '/');
        }
      } catch (error) {
        console.error(error);
        navigate(forwardPath ?? '/');
      }
    };

    checkStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
