import { Layout } from '@/components/ui/Layout';
import { useReset } from '@/lib/hooks/useReset';
import { LoaderCircle } from 'lucide-react';

export const ResetPage = () => {
  useReset();

  return (
    <Layout>
      <div className="grow flex flex-col items-center justify-center">
        <LoaderCircle className="animate-spin mb-2" />
        <p className="text-xl">Loading</p>
      </div>
    </Layout>
  );
};
