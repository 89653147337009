import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { WarnBeforeUnload } from '../WarnBeforeUnload';
import { useEffect, useState } from 'react';
import { useGlobalStore } from '@/lib/store/global';
import {
  ChildDetailsOptional,
  childDetailsOptionalSchema,
} from '@/lib/schemas/childDetailsSchema';
import { useNavigate } from 'react-router-dom';
import { getStepRoute } from '@/lib/utils/useStepNavigation';
import { getStepNumber, STEPS } from '@/lib/config/steps';

const ONE_MINUTE = 60 * 1000;
const THIRTY_MINUTES = 30 * ONE_MINUTE;

export function ContinueWhereYouLeftOff() {
  const { getFormData, saveFormData, resetStore, lastUpdate } =
    useGlobalStore();
  const navigate = useNavigate();

  const [showContinueMessage, setShowContinueMessage] = useState(
    !isEmpty(getFormData(childDetailsOptionalSchema)) &&
      Date.now() > lastUpdate + THIRTY_MINUTES,
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setShowContinueMessage(
        !isEmpty(getFormData(childDetailsOptionalSchema)) &&
          Date.now() > lastUpdate + THIRTY_MINUTES,
      );
    }, ONE_MINUTE);

    return () => clearInterval(interval);
  }, [getFormData, lastUpdate]);

  if (!showContinueMessage) {
    return <WarnBeforeUnload />;
  }

  function startNewOrder() {
    resetStore();
    navigate(getStepRoute(getStepNumber(STEPS.STEP_0)));
  }

  function updateTimestamp() {
    saveFormData({});
  }

  return (
    <AlertDialog defaultOpen>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Continue unfinished order?</AlertDialogTitle>
          <AlertDialogDescription>
            You have an unfinished order. Do you want to continue where you left
            off?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={startNewOrder}>
            Start New
          </AlertDialogCancel>
          <AlertDialogAction onClick={updateTimestamp}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function isEmpty(data?: ChildDetailsOptional) {
  return !data || Object.values(data).filter(Boolean).length < 1;
}
