import { useCallback, useState } from 'react';
import { Upload } from 'lucide-react';
import { cn } from '@/lib/utils';
import { isMobile } from '@/lib/utils/device';
import { MAXIMUM_PHOTOS } from '@/pages/photos';

export function ImageUpload({
  onUpload,
  isError,
  imagesUploaded,
}: {
  onUpload: (files: FileList) => void;
  isError: boolean;
  imagesUploaded: number;
}) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const onDragOver = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  }, []);
  const onDragLeave = useCallback(() => {
    setIsDraggingOver(false);
  }, []);
  const onDrop = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      setIsDraggingOver(false);
      if (e.dataTransfer?.files?.length) {
        onUpload(e.dataTransfer.files);
      }
    },
    [onUpload],
  );
  const onFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.files?.length) {
        onUpload(e.target.files);
      }
    },
    [onUpload],
  );

  const remainingPhotos = MAXIMUM_PHOTOS - imagesUploaded;

  const label = () => {
    if (imagesUploaded === 0) {
      return 'Click to add 2 to 5 photos';
    } else if (imagesUploaded === 1) {
      return 'Click to add 1 to 4 more photos';
    }

    if (remainingPhotos === 1) {
      return 'Optional: add 1 more photo';
    }

    return `Optional: add 1 to ${remainingPhotos} more photos`;
  };

  return (
    <label
      className={cn(
        'flex flex-col items-center justify-center rounded-md border-2 border-dashed border-border bg-white cursor-pointer w-full h-32 sm:h-44 px-4 gap-2',
        isDraggingOver && 'border-primary text-primary',
        isError && 'border-destructive',
      )}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="flex flex-col items-center justify-center">
        <Upload size={32} />
        <p className="text-md text-center mt-2">{label()}</p>
        {!isMobile() && (
          <p
            className={cn(
              'text-sm text-muted-foreground mt-2',
              isDraggingOver && 'text-primary',
            )}
          >
            Or drag and drop
          </p>
        )}
        <input
          type="file"
          accept=".png,.jpg,.jpeg,.avif,.webp,.heic"
          multiple={remainingPhotos !== 1}
          className="hidden"
          onChange={(e) => {
            onFileInputChange(e);
            e.target.value = '';
          }}
        />
      </div>
    </label>
  );
}
