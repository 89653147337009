import bannerImage from '@/assets/banners/7.avif';
import { Input } from '@/components/ui/input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { Banner } from '@/components/Banner';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import {
  ChildDetails,
  childDetailsSchema,
} from '@/lib/schemas/childDetailsSchema';
import { useEffect } from 'react';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { SubmitButton } from '@/components/SubmitButton';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import {
  CustomerDetails,
  CustomerDetailsOptional,
  customerDetailsOptionalSchema,
  customerDetailsSchema,
} from '@/lib/schemas/customerDetailsSchema';
import { useDataSubmission } from '@/lib/utils';
import { useGlobalStore } from '@/lib/store/global';
import { FormField } from '@/components/FormField';
import { STEPS } from '@/lib/config/steps';

const CURRENT_STEP = STEPS.STEP_7;

export function CustomerDetailsPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(
    childDetailsSchema.merge(customerDetailsOptionalSchema),
  );
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <CustomerDetailsForm data={data} /> : null;
}

function CustomerDetailsForm({
  data,
}: {
  data: ChildDetails & CustomerDetailsOptional;
}) {
  const { saveFormData } = useGlobalStore();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CustomerDetails>({
    resolver: zodResolver(customerDetailsSchema),
    defaultValues: {
      delivery_country: 'United Kingdom',
      ...data,
    },
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending } = useDataSubmission(CURRENT_STEP);
  const onSubmit = (data: CustomerDetails) =>
    submitData(data.user_email).finally(goToNextStep);

  return (
    <Layout>
      <FormHeader
        title="Your Contact Details"
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="h-fit">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>
              <FormField
                inputId="br_order_user_name"
                label="Your First Name"
                description={`The author of ${data.hero_name}'s book.`}
              >
                <Input
                  {...register('user_name')}
                  id="br_order_user_name"
                  placeholder="Ex. Margaret"
                  required
                  isError={!!errors.user_name}
                />
              </FormField>
              <FormField
                inputId="br_order_user_email"
                label="Email Address"
                description="To save your progress and easily return to your preview anytime."
              >
                <Input
                  {...register('user_email')}
                  id="br_order_user_email"
                  placeholder="Ex. margaret.thompson@gmail.com"
                  required
                  type="email"
                  isError={!!errors.user_email}
                />
              </FormField>
            </div>
          </div>
          <SubmitButton isLoading={isPending} />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
