import bannerImage from '@/assets/banners/1.avif';
import { Input } from '@/components/ui/input';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { Banner } from '@/components/Banner';
import {
  ChildDetails,
  ChildDetailsOptional,
  childDetailsOptionalSchema,
  childDetailsSchema,
} from '@/lib/schemas/childDetailsSchema';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import { DateInput } from '@/components/DateInput';
import { GenderSelect } from '@/components/GenderSelect';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { useEffect } from 'react';
import { SubmitButton } from '@/components/SubmitButton';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import { useDataSubmission, useInitialPageLoadSubmission } from '@/lib/utils';
import { useGlobalStore } from '@/lib/store/global';
import { FormField } from '@/components/FormField';
import { STEPS } from '@/lib/config/steps';

const CURRENT_STEP = STEPS.STEP_1;

export function ChildDetailsPage() {
  const { getFormData, deviceId } = useGlobalStore();
  const data = getFormData(childDetailsOptionalSchema);
  const submitInitialPageLoad = useInitialPageLoadSubmission();

  useEffect(() => {
    if (deviceId) {
      submitInitialPageLoad(deviceId);
    }
  }, [deviceId]);

  return <ChildDetailsForm data={data ?? {}} />;
}

function ChildDetailsForm({ data }: { data: ChildDetailsOptional }) {
  const { saveFormData } = useGlobalStore();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<ChildDetails>({
    resolver: zodResolver(childDetailsSchema),
    defaultValues: data,
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToNextStep } = useStepNavigation(CURRENT_STEP);
  const heroName = watch('hero_name');
  const { submitData, isPending } = useDataSubmission(CURRENT_STEP);
  const onSubmit = () => submitData().finally(goToNextStep);

  return (
    <Layout>
      <FormHeader
        title={`${heroName || 'Child'}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={() => {}}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="h-fit">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>
              <FormField
                inputId="br_order_hero_name"
                label="First Name"
                description="Who is this book for?"
              >
                <Input
                  {...register('hero_name')}
                  id="br_order_hero_name"
                  placeholder="Ex. Oliver"
                  required
                  isError={!!errors.hero_name}
                />
              </FormField>
              <FormField
                inputId="br_order_hero_location"
                label="Location"
                description="Where do they live?"
              >
                <Input
                  {...register('hero_location')}
                  id="br_order_hero_location"
                  placeholder="Ex. London, UK"
                  required
                  isError={!!errors.hero_location}
                />
              </FormField>
              <FormField
                label="Date of Birth"
                description="Help us tailor stories to their age."
              >
                <Controller
                  name="hero_dob"
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      value={field.value}
                      onChange={field.onChange}
                      isError={!!errors.hero_dob}
                    />
                  )}
                />
              </FormField>
              <FormField label="Gender">
                <Controller
                  name="hero_gender"
                  control={control}
                  render={({ field }) => (
                    <GenderSelect
                      onChange={field.onChange}
                      value={field.value}
                      isError={!!errors.hero_gender}
                    />
                  )}
                />
              </FormField>
            </div>
          </div>
          <SubmitButton isLoading={isPending} />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
