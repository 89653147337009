import * as RadixDialog from '@radix-ui/react-dialog';
import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';

interface DialogProps extends PropsWithChildren {
  buttonTitle: string;
  inlineButton?: boolean;
}

export function Dialog({
  buttonTitle,
  inlineButton = false,
  children,
}: DialogProps) {
  return (
    <RadixDialog.Root>
      <RadixDialog.Trigger asChild>
        {inlineButton ? (
          <a
            className={cn(
              'hover:bg-accent hover:text-accent-foreground',
              'whitespace-normal cursor-pointer text-primary inline',
            )}
          >
            {buttonTitle}
          </a>
        ) : (
          <button
            className={cn(
              'inline-flex items-center justify-center rounded-md',
              'px-4 py-2 hover:bg-accent hover:text-accent-foreground',
              'text-primary',
            )}
          >
            {buttonTitle}
          </button>
        )}
      </RadixDialog.Trigger>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className="fixed inset-0 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        <RadixDialog.Content
          aria-describedby={undefined}
          className={cn(
            'fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]',
            'w-full max-w-lg max-h-[75vh]',
            'bg-background shadow-lg duration-200',
            'data-[state=open]:animate-in data-[state=closed]:animate-out',
            'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
            'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
            'data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]',
            'data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
            'sm:rounded-lg flex flex-col',
          )}
        >
          <div className="p-6 h-full overflow-y-auto">{children}</div>
          <RadixDialog.Close
            className={cn(
              'max-sm:hidden absolute right-6 top-4 rounded-sm opacity-70 ring-offset-background',
              'transition-opacity hover:opacity-100 outline-none disabled:pointer-events-none',
            )}
          >
            <span className="sr-only">Close</span>✕
          </RadixDialog.Close>
          <RadixDialog.Close
            className={cn(
              'sm:hidden bg-inherit flex justify-center py-4 shadow border-t w-full rounded-none',
            )}
          >
            <p className="text-primary">Close</p>
          </RadixDialog.Close>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}

export function DialogTitle({ children }: PropsWithChildren) {
  return (
    <RadixDialog.Title className="font-semibold text-xl leading-none tracking-tight pr-6">
      {children}
    </RadixDialog.Title>
  );
}
