import { ArrowLeft } from 'lucide-react';
import { CloseFormButton } from './CloseFormButton';
import { Button } from './ui/button';
import {
  countAllSteps,
  getStepNumber,
  StepEventName,
} from '@/lib/config/steps';

export function FormHeader({
  title,
  currentStep,
  onBack,
}: {
  title: string;
  currentStep: StepEventName;
  onBack?: () => void;
}) {
  const stepNumber = getStepNumber(currentStep);

  return (
    <div className="pb-2 pl-5 pt-5 pr-5">
      <div className="flex flex-row">
        {stepNumber > 1 ? (
          <Button
            variant="ghost"
            size="icon"
            onClick={onBack}
            disabled={!onBack}
          >
            <ArrowLeft />
          </Button>
        ) : (
          <div className="w-[40px] h-[40px]" />
        )}
        <div className="flex-1">
          <h1 className="text-lg sm:text-2xl flex h-10 w-full justify-center items-center text-center">
            {title}
          </h1>
          <p className="text-sm w-full text-center text-muted-foreground pt-3">
            Step {stepNumber} of {countAllSteps}
          </p>
        </div>
        <CloseFormButton />
      </div>
    </div>
  );
}
