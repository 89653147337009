import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { Toaster } from './components/ui/toaster';
import { QueryClient } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { ScrollToTop } from './components/ScrollToTop';
import * as Sentry from '@sentry/react';

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: ONE_WEEK,
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  return (
    <>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister, maxAge: ONE_WEEK * 4 }}
      >
        <Outlet />
        <Toaster />
      </PersistQueryClientProvider>
      <ScrollToTop />
    </>
  );
}

const BlossomReadsApp = Sentry.withProfiler(App);

export default BlossomReadsApp;
