import { orderFormDataOptionalSchema } from '@/lib/store/global';
import { z } from 'zod';
import { FetchError } from '../utils/FetchError';
import { STEPS } from '../config/steps';

export const fetchLatestState = async (orderReferenceId: string) => {
  const url = new URL('/order-form-info', import.meta.env.VITE_API_URL);
  url.searchParams.append('order_reference_id', orderReferenceId);
  const response = await fetch(url);
  if (!response.ok) {
    throw new FetchError('Failed to fetch latest state', STEPS.STEP_1);
  }

  return response.json();
};

const orderStatusSchema = z.object({
  status: z.string().optional(),
  image_paths: z.array(
    z.object({ optimized: z.string(), original: z.string() }),
  ),
});

const formDataSchema = orderFormDataOptionalSchema.merge(orderStatusSchema);

export type FormData = z.infer<typeof formDataSchema>;
