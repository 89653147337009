import { useCallback } from 'react';
import { Trash2, LoaderCircle, ImageIcon, Check, X } from 'lucide-react';
import { uploadImages } from '@/lib/utils/orderForm/uploadImages';
import { useGlobalStore } from '@/lib/store/global';
import { UploadedImage } from '@/lib/schemas/uploadedImageSchema';
import { ImageUpload } from '../ImageUpload';
import { preloadImage } from '@/lib/utils/preloadImage';
import { MAXIMUM_PHOTOS } from '@/pages/photos';
import goodPhoto from '@/assets/examples/photos/good.avif';
import faceCoveredPhoto from '@/assets/examples/photos/covered.avif';
import blurryPhoto from '@/assets/examples/photos/blurry.avif';
import singlePersonPhoto from '@/assets/examples/photos/single.avif';
import * as Popover from '@radix-ui/react-popover';
import { useToast } from '../ui/use-toast';

export const ImageStep = ({
  images,
  isError,
  heroName,
}: {
  images: UploadedImage[];
  isError: boolean;
  heroName: string;
}) => {
  const { addImages, removeImage, setUploadingImages, order_reference_id } =
    useGlobalStore();
  const { toast } = useToast();

  const onUpload = useCallback(
    (files: FileList) => {
      const remainingSlots = MAXIMUM_PHOTOS - images.length;
      const filesUserAttemptedToUpload = Array.from(files);
      const filesToUpload = filesUserAttemptedToUpload.slice(0, remainingSlots);

      setUploadingImages(filesToUpload.map(URL.createObjectURL));
      uploadImages(order_reference_id, filesToUpload)
        .then(async (images) => {
          try {
            await Promise.all(images.map((i) => preloadImage(i.presigned_url)));
          } catch {
            // ignore preload errors
          }
          addImages(images);
        })
        .finally(() => setUploadingImages([]));

      if (filesUserAttemptedToUpload.length > remainingSlots) {
        toast({
          title: 'Too many photos',
          description: `You can upload up to ${MAXIMUM_PHOTOS} photos. We only used first ${remainingSlots} of the ${filesUserAttemptedToUpload.length} you selected.`,
          duration: 7000,
        });
      }
    },
    [addImages, order_reference_id, setUploadingImages, images.length, toast],
  );

  return (
    <div className="flex flex-col relative gap-8">
      <p className="text-2xl">
        Please upload two high-quality photos of {heroName} to help us create
        the best illustrations
      </p>

      <div className="grid grid-cols-4 gap-2">
        <GuidelinePhoto
          imageUrl={goodPhoto}
          altText={`Use only clear, well lit photos of ${heroName}`}
          isValid
        />
        <GuidelinePhoto
          imageUrl={faceCoveredPhoto}
          altText="Avoid photos with hats, glasses, etc."
        />
        <GuidelinePhoto
          imageUrl={blurryPhoto}
          altText={`${heroName}'s face needs to be clearly visible and in focus`}
        />
        <GuidelinePhoto
          imageUrl={singlePersonPhoto}
          altText={`${heroName} should be the only person in the photo`}
        />
      </div>

      {images.length < MAXIMUM_PHOTOS && (
        <ImageUpload
          onUpload={onUpload}
          isError={isError}
          imagesUploaded={images.length}
        />
      )}
      <ImageList images={images} onRemove={removeImage} />
      <p className="text-sm text-gray-500 w-full">
        🔒 Photos will be immediately deleted from our servers after
        illustrations are ready.
      </p>
    </div>
  );
};

const ImageList = ({
  images,
  onRemove,
}: {
  images: UploadedImage[];
  onRemove: (image: UploadedImage) => void;
}) => (
  <div className="flex flex-col gap-4">
    {images.map((image) => (
      <div key={image.s3_path} className="flex justify-between items-center">
        <div className="max-w-24 aspect-square relative overflow-hidden w-full">
          <img
            src={image.presigned_url}
            alt="hero"
            className="w-full h-full object-cover rounded-md"
          />
        </div>
        <div className="flex items-center p-4 mr-2">
          {image.s3_path === image.presigned_url ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            <button
              className="cursor-pointer disabled:text-muted-foreground"
              onClick={() => onRemove(image)}
            >
              <Trash2 />
            </button>
          )}
        </div>
      </div>
    ))}
    {images.length < 1 && <ImagePlaceholder />}
    {images.length < 2 && <ImagePlaceholder />}
  </div>
);

const ImagePlaceholder = () => (
  <div className="max-w-24 aspect-square relative overflow-hidden w-full">
    <div className="w-full h-full border-2 border-border rounded-md flex items-center justify-center">
      <ImageIcon className="w-8 h-8 text-gray-400" />
    </div>
  </div>
);

const GuidelinePhoto = ({
  altText,
  imageUrl,
  isValid = false,
}: {
  altText: string;
  imageUrl: string;
  isValid?: boolean;
}) => (
  <Popover.Root>
    <Popover.Trigger asChild>
      <div
        className={`h-fit cursor-pointer flex flex-col items-center overflow-hidden rounded-md ${isValid ? 'border-valid bg-valid' : 'border-primary bg-primary'}`}
        style={{ borderWidth: '1px' }}
      >
        <div className="aspect-[1/2]">
          <img
            src={imageUrl}
            alt={altText}
            className="w-full h-full object-cover"
          />
        </div>
        <div className={`w-full flex justify-center items-center h-4 sm:h-6`}>
          {isValid ? (
            <Check className="text-white p-1.5 sm:p-1" />
          ) : (
            <X className="text-white p-1.5 sm:p-1" />
          )}
        </div>
      </div>
    </Popover.Trigger>
    <Popover.Portal>
      <Popover.Content
        side="top"
        sideOffset={2}
        align="center"
        collisionPadding={20}
        className="max-w-64 bg-white relative py-2 px-3 rounded border drop-shadow-lg outline-none"
      >
        <p>{altText}</p>
        <Popover.Arrow className="fill-white" />
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
);
