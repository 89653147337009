import { Button } from '@/components/ui/button';
import bannerImage from '@/assets/banners/2.avif';
import { Textarea } from '@/components/ui/textarea';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { Banner } from '@/components/Banner';
import {
  Occasion,
  OccasionOptional,
  occasionOptionalSchema,
  occasionSchema,
} from '@/lib/schemas/occasionSchema';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import {
  ChildDetails,
  childDetailsSchema,
} from '@/lib/schemas/childDetailsSchema';
import { useOccasionSuggestions } from '@/lib/services/suggestions/occasion/useOccasionSuggestions';
import { Skeleton } from '@/components/ui/skeleton';
import { useEffect } from 'react';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { SubmitButton } from '@/components/SubmitButton';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import { useGlobalStore } from '@/lib/store/global';
import { useDataSubmission } from '@/lib/utils';
import { FormField } from '@/components/FormField';
import { STEPS } from '@/lib/config/steps';

const CURRENT_STEP = STEPS.STEP_2;

const loadingTags = Array.from({ length: 9 }).map(() =>
  Math.floor(Math.random() * (200 - 100) + 100),
);

export function OccasionPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(childDetailsSchema.merge(occasionOptionalSchema));
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <OccasionForm data={data} /> : null;
}

function OccasionForm({ data }: { data: ChildDetails & OccasionOptional }) {
  const { saveFormData } = useGlobalStore();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Occasion>({
    resolver: zodResolver(occasionSchema),
    defaultValues: data,
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending } = useDataSubmission(CURRENT_STEP);
  const onSubmit = () => submitData().finally(goToNextStep);

  const { suggestions, loading: loadingSuggestions } =
    useOccasionSuggestions(data);

  const occasion = watch('book_occasion');

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="h-fit">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>
              <FormField
                inputId="br_order_book_occasion"
                label="Occasion"
                description="Tell us the special reason for the book to make it truly unique."
              >
                <Textarea
                  {...register('book_occasion')}
                  id="br_order_book_occasion"
                  placeholder="Grandma's gift for her little bookworm"
                  required
                  isError={!!errors.book_occasion}
                />
                <div className="flex flex-row items-start justify-start gap-2">
                  <div className="flex flex-wrap gap-2">
                    {loadingSuggestions
                      ? loadingTags.map((width, i) => (
                          <Skeleton
                            key={i}
                            className={`h-[26px] rounded-full`}
                            style={{ width: `${width}px` }}
                          />
                        ))
                      : suggestions.map((suggestion) => (
                          <Button
                            type="button"
                            variant="tag"
                            size="none"
                            selected={occasion === suggestion}
                            key={suggestion}
                            onClick={() => {
                              setValue('book_occasion', suggestion);
                            }}
                            className="text-muted-foreground transition-colors h-fit"
                          >
                            {suggestion}
                          </Button>
                        ))}
                  </div>
                </div>
              </FormField>
            </div>
          </div>
          <SubmitButton isLoading={isPending} />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
