import { useNavigate } from 'react-router-dom';
import {
  countAllSteps,
  getStepNumber,
  StepEventName,
  STEPS,
} from '../config/steps';

export function useStepNavigation(stepEventName: StepEventName) {
  const navigate = useNavigate();
  const currentStep = getStepNumber(stepEventName);

  return {
    goToPreviousStep: () =>
      navigate(getStepRoute(Math.max(0, currentStep - 1))),
    goToNextStep: () =>
      navigate(getStepRoute(Math.min(countAllSteps, currentStep + 1))),
  };
}

export function getStepRoute(step: number) {
  switch (step) {
    case getStepNumber(STEPS.STEP_0):
    case getStepNumber(STEPS.STEP_1):
      return '/child-details';
    case getStepNumber(STEPS.STEP_2):
      return '/occasion';
    case getStepNumber(STEPS.STEP_3):
      return '/theme';
    case getStepNumber(STEPS.STEP_4):
      return '/note';
    case getStepNumber(STEPS.STEP_5):
      return '/message';
    case getStepNumber(STEPS.STEP_6):
      return '/photos';
    case getStepNumber(STEPS.STEP_7):
      return '/contact-details';
    case getStepNumber(STEPS.STEP_8):
      return '/similarity';
    case getStepNumber(STEPS.STEP_9):
      return '/cart';
    default:
      return '/';
  }
}
