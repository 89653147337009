import { STEPS } from '../config/steps';
import { FetchError } from '../utils/FetchError';

export const fetchImageUrls = async (orderReferenceId: string) => {
  const url = new URL(
    '/order-form-images-download',
    import.meta.env.VITE_API_URL,
  );
  url.searchParams.append('order_reference_id', orderReferenceId!);
  const response = await fetch(url);

  if (!response.ok) {
    throw new FetchError('Failed to fetch image urls', STEPS.STEP_1);
  }
  return response.json();
};
