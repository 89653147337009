export const STEPS = {
  STEP_0: 'Step 0: Order Form Landing',
  STEP_1: 'Step 1: Hero Data',
  STEP_2: 'Step 2: Occasion',
  STEP_3: 'Step 3: Theme',
  STEP_4: 'Step 4: Personalisation Note',
  STEP_5: 'Step 5: Dedication Message',
  STEP_6: 'Step 6: Hero Photos',
  STEP_7: 'Step 7: Customer Details',
  STEP_8: 'Step 8: Similarity Selection',
  STEP_9: 'Step 9: Overview',
};

export type StepEventName = (typeof STEPS)[keyof typeof STEPS];

export const countAllSteps = Object.keys(STEPS).length - 1;

export const getStepNumber = (step: StepEventName) =>
  Object.keys(STEPS).findIndex(
    (key) => STEPS[key as keyof typeof STEPS] === step,
  );
