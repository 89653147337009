import { useState } from 'react';
import { styleImages } from '@/lib/config/exampleImages';

export function StyleExamples() {
  const [example, setExample] = useState<0 | 1 | 2 | 3>(0);

  return (
    <div className="flex flex-col gap-3">
      <h2 className="w-full text-center text-2xl pb-1 mt-4 mb-4">
        Unique Illustration Styles
      </h2>
      <h4>
        Explore a selection of the 40+ unique styles we use to make each book
        truly special.
      </h4>
      <div className="flex gap-2">
        {([0, 1, 2, 3] as const).map((i) => (
          <button
            key={i}
            type="button"
            className="flex w-1/2"
            onClick={() => {
              setExample(i);
            }}
          >
            <img
              src={styleImages[i].photo}
              alt="book style hero"
              className={`border-2 ${example === i ? 'border-primary' : 'border-border opacity-70'}`}
            />
          </button>
        ))}
      </div>
      <div className="bg-card rounded-lg p-3 border text-sm">
        {styleImages[example].story}
      </div>
      <img src={styleImages[example].preview} alt="book style example" />
    </div>
  );
}
