export const queryKeys = {
  GENERATE_STORIES: 'generateStories',
  GENERATE_HERO_IMAGES: 'generateHeroImages',
  GENERATE_STORY_IMAGE: 'generateStoryImage',
  GET_STORIES: 'getStories',
  GET_HERO_IMAGES: 'getHeroImages',
  GET_STORY_IMAGE: 'getStoryImage',
  GET_FORM_INFO: 'getFormInfo',
  GET_IMAGE_URLS: 'getImageUrls',
};
