import { useEffect, useRef } from 'react';
import { useState } from 'react';

const easeInOut = (x: number) => {
  return 1 - Math.pow(1 - x, 3);
};

export const useProgress = ({
  duration = 90000, // due to easInOut it actually takes roughly 60 seconds
  start = false,
  finish = false,
  abort = false,
}: {
  duration?: number;
  start?: boolean;
  finish?: boolean;
  abort?: boolean;
} = {}) => {
  const [progress, setProgress] = useState(1);
  const [isInProgress, setIsInProgress] = useState(false);
  const cancelProgressRef = useRef(finish);

  useEffect(() => {
    cancelProgressRef.current = finish || abort;
  }, [finish, abort]);

  useEffect(() => {
    let animationFrameId: number;

    if (start) {
      const startTime = Date.now();

      setIsInProgress(true);
      const updateProgress = () => {
        if (cancelProgressRef.current) {
          cancelAnimationFrame(animationFrameId);
          return;
        }

        const elapsed = Date.now() - startTime;
        const ratio = Math.min(elapsed / duration, 1);
        const newProgress = Math.ceil(easeInOut(ratio) * 99);
        setProgress(newProgress);

        if (newProgress < 99) {
          animationFrameId = requestAnimationFrame(updateProgress);
        }
      };

      animationFrameId = requestAnimationFrame(updateProgress);
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [start]);

  // To prevent the progress animation from starting
  if (abort && isInProgress) {
    setIsInProgress(false);
  }

  // To finish the progress animation
  if (finish && isInProgress && progress < 100) {
    setProgress(100);
    setTimeout(() => {
      setIsInProgress(false);
    }, 1000);
  }

  const restart = () => {
    setProgress(1);
  };

  return { progress, isInProgress, restart };
};
