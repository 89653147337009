import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  enabled: import.meta.env.VITE_ENVIRONMENT === 'production',
  dsn: 'https://69f586dbdf7f8d1c6143786c05c8d2ed@o4508046316601344.ingest.de.sentry.io/4508046375583824',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        'https://order.blossomreads.com',
        'https://api.blossomreads.com',
      ],
    }),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.browserProfilingIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//, /^https:\/\/api\.blossomreads\.com\//], // Sync with VITE_API_URL
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,
});
