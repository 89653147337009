import { z } from 'zod';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useGlobalStore } from '../store/global';
import { Gender } from '../schemas/genderSchema';
import { childDetailsSchema } from '../schemas/childDetailsSchema';
import { occasionSchema } from '../schemas/occasionSchema';
import { themeSchema } from '../schemas/themeSchema';
import { personalisationNoteSchema } from '../schemas/personalisationNoteSchema';
import { dedicationMessageSchema } from '../schemas/dedicationMessageSchema';
import { useDisplayErrorToast } from '../utils/useDisplayErrorToast';
import { queryKeys } from './queryKeys';
import { FetchError } from '../utils/FetchError';
import { STEPS } from '../config/steps';
import { useEffect } from 'react';

const MAX_FETCH_ATTEMPTS = 60;
const FETCH_POLL_INTERVAL = 1000;

type GetStoriesParams = {
  hero_name: string;
  hero_gender: Gender;
  hero_dob: string;
  hero_location: string;
  personalisation_note: string;
  dedication_message: string;
  language: 'en';
  theme: string;
  occasion: string;
};

export const getStoriesSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(themeSchema)
  .merge(personalisationNoteSchema)
  .merge(dedicationMessageSchema);

const storiesSchema = z.object({
  story_1: z.string(),
  story_2: z.string(),
  story_3: z.string(),
  story_4: z.string(),
  story_5: z.string(),
  story_6: z.string(),
  title_1: z.string(),
  title_2: z.string(),
  title_3: z.string(),
  title_4: z.string(),
  title_5: z.string(),
  title_6: z.string(),
});

export const useStories = ({ start }: { start: boolean }) => {
  const { order_reference_id } = useGlobalStore();

  return useQuery({
    queryKey: [queryKeys.GET_STORIES, order_reference_id],
    queryFn: () => getStories(order_reference_id),
    enabled: start,
    retry: MAX_FETCH_ATTEMPTS,
    retryDelay: FETCH_POLL_INTERVAL,
  });
};

export const useStoriesGeneration = ({ start }: { start: boolean }) => {
  const { order_reference_id, getFormData } = useGlobalStore();
  const queryClient = useQueryClient();
  const dataForStories = getFormData(getStoriesSchema);

  const params = getParams(dataForStories!);

  const { isError, isSuccess, isLoading, isFetching } = useQuery({
    queryKey: [queryKeys.GENERATE_STORIES, order_reference_id, dataForStories],
    queryFn: () => startStoryGeneration(order_reference_id, params!),
    enabled: !!params && start,
  });

  useDisplayErrorToast({ isError: start && isError });

  useEffect(() => {
    if (isFetching) {
      queryClient.resetQueries({
        queryKey: [queryKeys.GET_STORIES, order_reference_id],
      });
    }
  }, [isFetching, queryClient, order_reference_id]);

  return {
    isStartStoriesGenerationLoading: isLoading,
    isStartStoriesGenerationSuccess: isSuccess,
    isStartStoriesGenerationError: isError,
  };
};

async function startStoryGeneration(
  order_reference_id: string,
  params: GetStoriesParams,
) {
  const url = new URL('/preview/order-assistant', import.meta.env.VITE_API_URL);
  url.searchParams.append('task', 'write_stories');
  url.searchParams.append('order_reference_id', order_reference_id);

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!response.ok) {
    throw new Error('Failed to start story generation');
  }

  return (
    z.object({ status: z.string() }).parse(await response.json()).status ===
    'success'
  );
}

export async function getStories(order_reference_id: string) {
  const url = new URL(
    '/preview/order-assistant/get-results',
    import.meta.env.VITE_API_URL,
  );
  url.searchParams.append('task', 'write_stories');
  url.searchParams.append('order_reference_id', order_reference_id);

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    throw new FetchError('Failed to fetch preview stories', STEPS.STEP_1);
  }

  const data = await response.json();
  if (!data || Object.keys(data).length === 0) {
    throw new FetchError('Stories do not exist', STEPS.STEP_1);
  }

  return storiesSchema.parse(data);
}

const getParams = (data: z.infer<typeof getStoriesSchema>) => {
  const params: GetStoriesParams | undefined = data
    ? {
        hero_name: data.hero_name,
        hero_gender: data.hero_gender,
        hero_dob: data.hero_dob,
        hero_location: data.hero_location,
        personalisation_note: data.book_personalisation_note ?? '',
        dedication_message: data.book_dedication_message ?? '',
        language: 'en',
        theme: data.book_theme ?? '',
        occasion: data.book_occasion,
      }
    : undefined;

  return params;
};
