import { Skeleton } from '@/components/ui/skeleton';
import { useStories } from '@/lib/hooks/useStories';
import {
  startStoryImageGeneration,
  useOrderReferenceIdAndParams,
  useStoryImage,
} from '@/lib/hooks/useStoryImage';
import { useProgress } from '@/lib/utils/useProgress';
import { Fragment, useState } from 'react';
import { ReactTyped } from 'react-typed';
import { Dialog, DialogTitle } from './ui/dialog';
import { Button } from './ui/button';
import { LoaderCircle } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@/lib/hooks/queryKeys';
import { z } from 'zod';
import { cartPageSchema } from '@/lib/schemas/cartSchema';

export const StorybookPreview: React.FC<{
  data: z.infer<typeof cartPageSchema>;
}> = ({ data }) => {
  const {
    data: storyImage,
    isLoading: isStoryImageLoading,
    isPending: isStoryImagePending,
  } = useStoryImage();
  const { data: stories } = useStories({ start: true });
  const [startedTyping, setStartedTyping] = useState({
    title_2: true,
    title_3: false,
    title_4: false,
    title_5: false,
    title_6: false,
    title_7: false,
    story_2: false,
    story_3: false,
    story_4: false,
    story_5: false,
    story_6: false,
  });

  const [isRegenerateStoryImage, setIsRegenerateStoryImage] = useState(false);

  const isLoadingFinishedWithNoData = !isStoryImagePending && !storyImage;

  const {
    progress,
    isInProgress: isProgressAnimationInProgress,
    restart: restartProgressAnimation,
  } = useProgress({
    start: isStoryImageLoading,
    finish: !!storyImage,
    abort: isLoadingFinishedWithNoData,
    duration: 60000,
  });

  const queryClient = useQueryClient();

  const { order_reference_id, params } = useOrderReferenceIdAndParams();

  const handleRetryClick = async () => {
    setIsRegenerateStoryImage(true);
    await queryClient.fetchQuery({
      queryKey: [queryKeys.GENERATE_STORY_IMAGE],
      queryFn: () => startStoryImageGeneration(order_reference_id, params!),
      staleTime: 0,
    });
    queryClient.invalidateQueries({ queryKey: [queryKeys.GET_STORY_IMAGE] });
    setIsRegenerateStoryImage(false);
    restartProgressAnimation();
  };

  const getStoryImageComponent = () => {
    if (isLoadingFinishedWithNoData) {
      return (
        <LoadingFailed
          handleClick={handleRetryClick}
          isButtonLoading={isRegenerateStoryImage}
        />
      );
    }

    if (progress === 99) {
      return <LoadingTimedOut email={data.user_email} />;
    }

    if (!isProgressAnimationInProgress && storyImage) {
      return <img src={storyImage} alt="book preview" />;
    }

    if (isProgressAnimationInProgress) {
      return <LoadingStoryImage progress={progress} />;
    }

    return <Skeleton className="h-full" />;
  };

  const typingInProgress = startedTyping.title_3 && !startedTyping.title_7;

  return (
    <div className="flex flex-col gap-2 my-6">
      <h2 className="w-full text-2xl font-medium mb-2">
        First Story Two-Page Spread
      </h2>

      <div className="flex flex-col gap-2">
        <div className="flex flex-col w-full rounded-lg bg-card border overflow-hidden aspect-[2/1]">
          {getStoryImageComponent()}
        </div>

        <p className="text-sm text-gray-500 w-full">
          Initial preview, the final illustrations will be artistically refined
          by our artists. 🧑‍🎨
        </p>
      </div>

      <h2 className="w-full text-2xl font-medium mt-6">
        Remaining Story Teasers
      </h2>

      <p className="text-xl text-muted-foreground mb-2">
        We'll send the full book for review after you complete the order.{' '}
        <WhyALimitedPreviewDialog />
      </p>

      {([2, 3, 4, 5, 6] as const).map((storyIndex) => {
        const storyTitle = stories
          ? `${storyIndex}. ${stories[`title_${storyIndex}`]}`
          : '';
        const storyText = stories ? stories[`story_${storyIndex}`] : '';

        if (!isProgressAnimationInProgress && !typingInProgress) {
          return (
            <div
              key={storyIndex}
              className="flex flex-col gap-2 border py-2 px-3 rounded-lg bg-card mb-2"
            >
              <h3 className="font-semibold">{storyTitle}</h3>
              <p>{storyText}</p>
            </div>
          );
        }

        return (
          <Fragment key={storyIndex}>
            {startedTyping[`title_${storyIndex}`] && (
              <div className="flex flex-col gap-2 border py-2 px-3 rounded-lg bg-card mb-2">
                <h3 className="font-semibold">
                  <ReactTyped
                    strings={[storyTitle]}
                    onComplete={() => {
                      setStartedTyping((prev) => ({
                        ...prev,
                        [`story_${storyIndex}`]: true,
                      }));
                    }}
                    typeSpeed={54}
                    showCursor={false}
                  />
                </h3>
                {startedTyping[`story_${storyIndex}`] && (
                  <p>
                    <ReactTyped
                      strings={[storyText]}
                      onComplete={() =>
                        setStartedTyping((prev) => ({
                          ...prev,
                          [`title_${storyIndex + 1}`]: true,
                        }))
                      }
                      typeSpeed={54}
                      showCursor={false}
                    />
                  </p>
                )}
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

const LoadingTimedOut = ({ email }: { email: string }) => (
  <div className="grid grid-cols-2 gap-2 relative h-full">
    <Skeleton className="col-span-2 rounded-none" />
    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center gap-2 p-4">
      <p className="text-center pb-2">
        Thanks for your patience - due to high demand, it's taking a bit longer.
      </p>
      <p className="text-center">
        We'll email your illustrations to
        <br />
        <span className="font-semibold break-words">
          {email.split('@')[0]}
          <wbr />@{email.split('@')[1]}
        </span>
        <br />
        as soon as they're ready.
      </p>
    </div>
  </div>
);

const LoadingStoryImage = ({ progress }: { progress: number }) => (
  <div className="relative h-full">
    <Skeleton className="h-full" />
    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center gap-2 p-4">
      <p className="text-center text-lg">
        {progress < 25
          ? 'Analysing your chosen illustration'
          : progress < 50
            ? 'Expanding the illustration'
            : progress < 75
              ? 'Adding story title and text'
              : progress < 100
                ? 'Finishing touches'
                : 'Two-page spread is ready!'}
        {progress < 100 && (
          <span className="font-semibold">
            <span className="animate-[pulse_1s_infinite]">.</span>
            <span className="animate-[pulse_1s_infinite_0.25s]">.</span>
            <span className="animate-[pulse_1s_infinite_0.5s]">.</span>
          </span>
        )}
      </p>
      <div className="flex items-center gap-1">
        <div className="flex items-center justify-between gap-2">
          <div className="relative w-48 h-2 bg-secondary rounded-full">
            <div
              className="absolute h-full bg-primary rounded-full transition-all"
              style={{ width: `${progress}%` }}
            />
          </div>
          <div className="text-lg font-medium flex w-12 justify-end">
            <span className="text-right">{progress}</span>
            <span>%</span>
          </div>
        </div>
      </div>
      <p className="text-center text-muted-foreground">
        Usually we're done in less than a minute
      </p>
    </div>
  </div>
);

const LoadingFailed = ({
  handleClick,
  isButtonLoading,
}: {
  handleClick: () => void;
  isButtonLoading: boolean;
}) => (
  <div className="flex flex-col justify-center items-center rounded-md bg-border gap-1 p-2 h-full">
    <p className="text-center text-xl">Ugh, apologies.</p>
    <p className="text-center">
      Seems like we ran out of paint.
      <br />
      Retry now or come back in 30 minutes.
    </p>
    <p className="text-center"></p>
    <Button
      variant="outline"
      type="button"
      onClick={handleClick}
      disabled={isButtonLoading}
    >
      {isButtonLoading ? (
        <LoaderCircle className="animate-spin" />
      ) : (
        'Try Again'
      )}
    </Button>
  </div>
);

const WhyALimitedPreviewDialog = () => (
  <Dialog buttonTitle="Why a limited preview?" inlineButton>
    <div className="grid gap-4">
      <div className="space-y-4">
        <DialogTitle>Why a Limited Preview?</DialogTitle>
        <p>
          At Blossom Reads, each book is lovingly crafted from scratch, giving
          your child a truly unique experience. Here's why we show you a full
          preview of just one story, with a glimpse of the rest:
        </p>
        <ul className="list-disc list-inside">
          <li>
            <span className="font-semibold">
              High Quality with Less Effort:
            </span>{' '}
            Unlike complex platforms, we make ordering simple, while we handle
            the finishing touches so you can enjoy a quick, 5-minute ordering
            experience.
          </li>
          <li>
            <span className="font-semibold">Caring Attention to Detail:</span>{' '}
            Creating bespoke illustrations takes time and care. By previewing
            only part, we ensure a polished, beautifully crafted final product.
          </li>
          <li>
            <span className="font-semibold">Room for Feedback:</span> Don’t
            worry, you can still request changes! This helps us ensure every
            detail is right for your child.
          </li>
        </ul>
        <p>
          This approach lets us bring you a high-quality, joyful reading
          experience without the hassle or waiting.
        </p>
      </div>
    </div>
  </Dialog>
);
