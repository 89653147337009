import { z } from 'zod';

export const uploadedImageSchema = z.object({
  original_file_name: z.string(),
  presigned_url: z.string(),
  s3_path: z.string(),
  s3_path_original: z.string(),
});
export type UploadedImage = z.infer<typeof uploadedImageSchema>;

export const uploadedImagesSchema = z.array(uploadedImageSchema);
