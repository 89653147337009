import { useCallback, useEffect, useState } from 'react';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from './ui/carousel';
import { boyImages, girlImages } from '@/lib/config/exampleImages';

export function Examples({ gender }: { gender: 'boy' | 'girl' }) {
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [api, setApi] = useState<CarouselApi>();

  const onScroll = useCallback(() => {
    if (api) {
      setIsFirstSlide(api.slidesInView().includes(0));
    }
  }, [api]);

  useEffect(() => {
    if (!api) {
      return;
    }
    api
      .on('reInit', onScroll)
      .on('scroll', onScroll)
      .on('slideFocus', onScroll);
  }, [api, onScroll]);

  return (
    <div className="flex flex-col gap-3">
      <h2 className="w-full text-center text-2xl pb-1 mt-4 mb-4">
        What to Expect?
      </h2>
      <Carousel setApi={setApi} className="select-none">
        <CarouselContent>
          {(gender === 'girl' ? girlImages : boyImages).map((img, i) => (
            <CarouselItem key={i}>
              <img src={img} alt={`book example ${i}`} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious type="button" />
        <CarouselNext
          type="button"
          className={
            isFirstSlide ? 'text-transparent hover:text-transparent' : ''
          }
        />
      </Carousel>
    </div>
  );
}
