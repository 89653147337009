import bannerImage from '@/assets/banners/4.avif';
import { Textarea } from '@/components/ui/textarea';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { Banner } from '@/components/Banner';
import { occasionSchema } from '@/lib/schemas/occasionSchema';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import { childDetailsSchema } from '@/lib/schemas/childDetailsSchema';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { SubmitButton } from '@/components/SubmitButton';
import {
  PersonalisationNote,
  personalisationNoteSchema,
} from '@/lib/schemas/personalisationNoteSchema';
import { useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Stars } from '@/components/icons/stars';
import {
  getPersonalisationNoteSuggestion,
  preloadPersonalisationNoteSuggestions,
} from '@/lib/services/suggestions/personalisationNote/getPersonalisationNoteSuggestion';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import { themeSchema } from '@/lib/schemas/themeSchema';
import { useGlobalStore } from '@/lib/store/global';
import { z } from 'zod';
import { useDataSubmission } from '@/lib/utils';
import { FormField } from '@/components/FormField';
import { Trash2 } from 'lucide-react';
import { STEPS } from '@/lib/config/steps';

const CURRENT_STEP = STEPS.STEP_4;

const personalisationNotePageSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(themeSchema)
  .merge(personalisationNoteSchema);

export function PersonalisationNotePage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(personalisationNotePageSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <NotesForm data={data} /> : null;
}

function NotesForm({
  data,
}: {
  data: z.infer<typeof personalisationNotePageSchema>;
}) {
  const { saveFormData, order_reference_id } = useGlobalStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<PersonalisationNote>({
    resolver: zodResolver(personalisationNoteSchema),
    defaultValues: data,
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending } = useDataSubmission(CURRENT_STEP);
  const onSubmit = () => submitData().finally(goToNextStep);
  useEffect(() => {
    preloadPersonalisationNoteSuggestions(order_reference_id, data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isTextareaValueClearable = !!getValues('book_personalisation_note');
  const clearTextareaValue = () => setValue('book_personalisation_note', '');

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="h-fit">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>
              <FormField
                inputId="br_order_book_personalisation_note"
                label="Add a Personal Touch"
                description="Add names, ideas or special details. Or leave it to us - your book will be unique either way."
                optional
              >
                <Textarea
                  {...register('book_personalisation_note')}
                  id="br_order_book_personalisation_note"
                  placeholder="Your personal notes"
                  isError={!!errors.book_personalisation_note}
                />
                <div className="w-full flex items-start justify-between gap-2">
                  <Button
                    variant="outline"
                    type="button"
                    className="uppercase border-foreground flex items-center justify-center gap-2"
                    onClick={() => {
                      setValue(
                        'book_personalisation_note',
                        getPersonalisationNoteSuggestion(
                          order_reference_id,
                          data,
                        ),
                      );
                    }}
                  >
                    <Stars />
                    Inspire me
                  </Button>
                  {isTextareaValueClearable && (
                    <Button
                      variant="ghost"
                      size="icon"
                      className="border-foreground align-self-end"
                      onClick={clearTextareaValue}
                    >
                      <Trash2 />
                    </Button>
                  )}
                </div>
              </FormField>
            </div>
          </div>
          <SubmitButton isLoading={isPending} />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
