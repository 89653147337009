export class FetchError extends Error {
  navigateTo: string;
  abortRetry: boolean;

  constructor(message: string, navigateTo: string, abortRetry = false) {
    super(message);
    this.name = 'FetchError';
    this.navigateTo = navigateTo;
    this.abortRetry = abortRetry;
  }
}
