import { z } from 'zod';
import * as Popover from '@radix-ui/react-popover';
import { Button } from './ui/button';
import { Info } from 'lucide-react';
import { cartPageSchema } from '@/lib/schemas/cartSchema';

export const StorybookDetails: React.FC<{
  data: z.infer<typeof cartPageSchema>;
}> = ({ data }) => {
  return (
    <div className="flex flex-col gap-4 w-full">
      <h2 className="w-full text-2xl font-medium">Book Details</h2>

      <div className="flex flex-col gap-3">
        <Detail
          label="Occasion"
          explainer="Special occasion for which this book is being crafted."
          content={data.book_occasion}
        />

        {data.book_theme && (
          <Detail
            label="Theme"
            explainer="Bespoke adventures will be brought to life through stories inspired by this theme."
            content={data.book_theme}
          />
        )}

        {data.book_personalisation_note && (
          <Detail
            label="Personalisation Note"
            explainer="Unique touches that make each story truly engaging."
            content={data.book_personalisation_note}
          />
        )}

        {data.book_dedication_message && (
          <Detail
            label="Message"
            explainer="This message will be printed in one of the first pages."
            content={data.book_dedication_message}
          />
        )}
      </div>
    </div>
  );
};

const Label = ({ content }: { content: string }) => (
  <p className="font-semibold">{content}</p>
);

const Explainer = ({ content }: { content: string }) => (
  <Popover.Root>
    <Popover.Trigger asChild>
      <Button
        variant="ghost"
        size="none"
        className="w-4 h-4 text-muted-foreground"
      >
        <Info />
      </Button>
    </Popover.Trigger>
    <Popover.Portal>
      <Popover.Content
        side="top"
        sideOffset={2}
        align="start"
        collisionPadding={20}
        className="max-w-64 bg-white relative py-2 px-3 rounded border drop-shadow-lg"
      >
        <p>{content}</p>
        <Popover.Arrow className="fill-white" />
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
);

const Detail = ({
  label,
  explainer,
  content,
}: {
  label: string;
  explainer: string;
  content: string;
}) => (
  <div>
    <div className="flex items-center gap-2 pl-3 pb-1">
      <Label content={label} />
      <Explainer content={explainer} />
    </div>
    <p className="bg-card py-2 px-3 rounded-lg border">{content}</p>
  </div>
);
