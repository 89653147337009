import { Dialog, DialogTitle } from './ui/dialog';

interface ReadStoryDialogProps {
  storyTitle: string;
  storyText: string;
}

export const ReadStoryDialog = ({
  storyTitle,
  storyText,
}: ReadStoryDialogProps) => (
  <Dialog buttonTitle="Read Story">
    <div className="grid gap-4">
      <div className="space-y-4">
        <DialogTitle>{storyTitle}</DialogTitle>
        <p className="text-md whitespace-pre-wrap">{storyText}</p>
      </div>
    </div>
  </Dialog>
);
