import './instrument';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import '@/styles/global.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorPage } from './pages/errorPage.tsx';
import { ChildDetailsPage } from './pages/child-details.tsx';
import { OccasionPage } from './pages/occasion.tsx';
import { ThemePage } from './pages/theme.tsx';
import { PersonalisationNotePage } from './pages/note.tsx';
import { DedicationMessagePage } from './pages/message.tsx';
import { PhotosPage } from './pages/photos.tsx';
import { CustomerDetailsPage } from './pages/customer-details.tsx';
import { CartPage } from './pages/cart.tsx';
import { SimilarityPage } from './pages/similarity.tsx';
import { handleRedirect } from './lib/loaders/handleRedirect.ts';
import { RehydratePage } from './pages/rehydrate.tsx';
import { CheckStatusPage } from './pages/check-status.tsx';
import * as Sentry from '@sentry/react';
import { ResetPage } from './pages/reset.tsx';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    loader: handleRedirect,
    children: [
      {
        path: 'rehydrate',
        element: <RehydratePage />,
      },
      { path: 'check-status', element: <CheckStatusPage /> },
      { path: 'reset', element: <ResetPage /> },
      {
        index: true,
        element: <ChildDetailsPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: ':lang',
        element: <ChildDetailsPage />,
      },
      {
        path: 'child-details',
        element: <ChildDetailsPage />,
      },
      {
        path: 'occasion',
        element: <OccasionPage />,
      },
      {
        path: 'theme',
        element: <ThemePage />,
      },
      {
        path: 'note',
        element: <PersonalisationNotePage />,
      },
      {
        path: 'message',
        element: <DedicationMessagePage />,
      },
      {
        path: 'photos',
        element: <PhotosPage />,
      },
      {
        path: 'contact-details',
        element: <CustomerDetailsPage />,
      },
      {
        path: 'similarity',
        element: <SimilarityPage />,
      },
      {
        path: 'cart',
        element: <CartPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
