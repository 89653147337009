import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import { useEffect, useState } from 'react';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import * as Separator from '@radix-ui/react-separator';
import { format, addBusinessDays } from 'date-fns';
import {
  Check,
  Plus,
  LoaderCircle,
  Book,
  Headphones,
  Circle,
  HeartHandshake,
  CircleCheck,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Cart, cartPageSchema, cartSchema } from '@/lib/schemas/cartSchema';
import {
  AUDIO_BOOK_PRICE,
  EXPRESS_SHIPPING_PRICE,
  FIRST_BOOK_PRICE,
  STANDARD_SHIPPING_PRICE,
} from '@/lib/config/prices';
import { setOpenedCheckout, useGlobalStore } from '@/lib/store/global';
import { z } from 'zod';
import { useDataSubmission } from '@/lib/utils';
import { StorybookPreview } from '@/components/StorybookPreview';
import { Examples } from '@/components/Examples';
import { StyleExamples } from '@/components/StyleExamples';
import { CustomerReviews } from '@/components/Reviews';
import { useToast } from '@/components/ui/use-toast';
import { StorybookDetails } from '@/components/StorybookDetails';
import { STEPS } from '@/lib/config/steps';
import { Dialog, DialogTitle } from '@/components/ui/dialog';

const CURRENT_STEP = STEPS.STEP_9;

const currency = 'GBP';
const currencySymbol = '£';

export function CartPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(cartPageSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <CartForm data={data} /> : null;
}

function CartForm({ data }: { data: z.infer<typeof cartPageSchema> }) {
  const { saveFormData, order_reference_id } = useGlobalStore();
  const { handleSubmit, watch, setValue, formState } = useForm<Cart>({
    resolver: zodResolver(cartSchema),
    defaultValues: {
      book_quantity: data.book_quantity ?? 1,
      is_audio_book:
        'is_audio_book' in data ? Boolean(data.is_audio_book) : false,
      is_paid_shipping:
        'is_paid_shipping' in data ? data.is_paid_shipping : false,
    },
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending: isPendingSubmit } =
    useDataSubmission(CURRENT_STEP);
  const { toast } = useToast();
  const [isStripeLoading, setIsStripeLoading] = useState(false);

  const onSubmit = async () => {
    if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
      throw new Error('Aborting order confirmation in dev mode');
    }
    try {
      await submitData();
      setOpenedCheckout();
      redirectToPayment();
    } catch (error) {
      console.error(error);
      toast({
        variant: 'destructive',
        title: 'Failed to submit your response',
        description:
          'There was an issue submitting your response. Please try again later.',
      });
    }
  };
  const includeAudioBook = watch('is_audio_book');
  const bookQuantity = watch('book_quantity');
  const expressShipping = watch('is_paid_shipping');

  const redirectToPayment = async () => {
    setIsStripeLoading(true);

    const url = new URL(
      '/stripe/stripe-payment-url',
      import.meta.env.VITE_API_URL,
    );
    const products = ['book'];
    if (includeAudioBook) {
      products.push('audio');
    }
    if (expressShipping) {
      products.push('express_shipping');
    }

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        products: products,
        customer_email: data.user_email,
        client_reference_id: order_reference_id,
        language: 'en',
        testing: import.meta.env.VITE_ENVIRONMENT === 'development',
        book_quantity: bookQuantity,
        currency: currency.toLowerCase(),
      }),
    });

    if (!res.ok) {
      console.error('Failed to fetch payment url');
      return;
    }

    try {
      const { payment_url } = z
        .object({ payment_url: z.string() })
        .parse(await res.json());

      window.location.href = payment_url;
    } catch {
      console.error('Failed to parse payment url response');
    } finally {
      setIsStripeLoading(false);
    }
  };

  const getTotal = () =>
    (
      (FIRST_BOOK_PRICE +
        (includeAudioBook ? AUDIO_BOOK_PRICE : 0) +
        (expressShipping ? EXPRESS_SHIPPING_PRICE : STANDARD_SHIPPING_PRICE)) /
      100
    ).toFixed(2);

  return (
    <Layout>
      <FormHeader
        title="Book Preview & Order"
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="flex flex-col gap-6 w-full">
              <StorybookDetails data={data} />
              <StorybookPreview data={data} />

              <div className="flex flex-col gap-5 w-100 bg-white p-5 max-sm:-ml-5 max-sm:-mr-5">
                <div className="w-full text-center mt-6 mb-3">
                  <h2 className="text-2xl font-medium leading-none tracking-tight">
                    Complete Your Order
                  </h2>

                  <HowToOrderMultipleBooksDialog />
                </div>

                <ul className="flex flex-col gap-3">
                  <li className="flex flex-col gap-3">
                    <div className="flex flex-row items-center justify-between gap-2">
                      <div className="flex items-center gap-3">
                        <Book />
                        <p className="text-lg">{data.hero_name}'s Book</p>
                      </div>
                      <p className="whitespace-nowrap">
                        {`${currencySymbol} ${(FIRST_BOOK_PRICE / 100).toFixed(2)}`}
                      </p>
                    </div>
                  </li>

                  <Divider />

                  <li className="flex flex-col gap-3">
                    <div className="flex flex-row items-center justify-between gap-2">
                      <div className="flex items-center gap-3">
                        <Headphones />
                        <p className="text-lg">Add Audiobook</p>
                      </div>
                      <p>{`${currencySymbol} ${AUDIO_BOOK_PRICE / 100}`}</p>
                    </div>
                    <div className="flex justify-between items-start gap-8">
                      <p className="font-light text-muted-foreground">
                        Listen to {data.hero_name}'s Book stories.
                      </p>
                      <Checkmark
                        isChecked={includeAudioBook}
                        onClick={() =>
                          setValue('is_audio_book', !includeAudioBook)
                        }
                      />
                    </div>
                  </li>

                  <Divider />

                  <li className="flex flex-col gap-3">
                    <p className="text-xl leading-tight my-2">Shipping Speed</p>

                    <div className="flex flex-col">
                      <DeliveryOption
                        title="Standard"
                        subtitle1="Estimated Delivery"
                        subtitle2={formatWorkingDaysRange(8, 16)}
                        price={STANDARD_SHIPPING_PRICE}
                        onClick={() => setValue('is_paid_shipping', false)}
                        checked={!expressShipping}
                      />
                      <DeliveryOption
                        title="Express"
                        subtitle1="Estimated Delivery (trackable)"
                        subtitle2={formatWorkingDaysRange(5, 13)}
                        price={EXPRESS_SHIPPING_PRICE}
                        onClick={() => setValue('is_paid_shipping', true)}
                        checked={expressShipping}
                      />
                    </div>
                  </li>

                  <Divider />

                  <li className="flex justify-between">
                    <p className="font-medium text-xl">Total to Pay:</p>
                    <p className="font-medium text-xl">
                      {currencySymbol} {getTotal()}
                    </p>
                  </li>
                </ul>
                <div className="flex justify-center">
                  <CheckoutButton
                    isLoading={isPendingSubmit || isStripeLoading}
                  />
                </div>

                <div className="flex flex-col gap-4 py-4 px-6">
                  <div className="flex items-center justify-center gap-3">
                    <HeartHandshake />
                    <p className="text-lg">100% Satisfaction Guaranteed</p>
                  </div>
                  <ol
                    role="list"
                    className="flex flex-col gap-2 list-decimal text-muted-foreground"
                  >
                    <li className="font-light">
                      Receive the entire digital book to review.
                    </li>
                    <li className="font-light">
                      We’ll make any changes you need.
                    </li>
                    <li className="font-light">
                      Love the book or get a{' '}
                      <a
                        className="font-light text-tertiary"
                        target="_blank"
                        href="https://www.blossomreads.com/return-policy"
                      >
                        full refund ↗
                      </a>
                      .
                    </li>
                  </ol>
                </div>
              </div>

              <CustomerReviews />
              <Examples gender={data.hero_gender} />
              <StyleExamples />
            </div>
          </div>
        </FormBody>
      </form>
      {!formState.isSubmitting && !formState.isSubmitted && (
        <ContinueWhereYouLeftOff />
      )}
    </Layout>
  );
}

const Checkmark = ({
  isChecked,
  onClick,
}: {
  isChecked: boolean;
  onClick: () => void;
}) => (
  <Button
    type="button"
    size="icon"
    className="min-w-10"
    variant={isChecked ? 'default' : 'outline'}
    onClick={onClick}
  >
    {isChecked ? <Check /> : <Plus />}
  </Button>
);

const Divider = () => (
  <Separator.Root
    orientation="horizontal"
    className="h-[1px] w-full bg-border my-2"
  />
);

const CheckoutButton = ({ isLoading }: { isLoading: boolean }) => (
  <Button
    type="submit"
    className="w-full text-lg rounded-md h-12"
    disabled={isLoading}
  >
    {isLoading ? (
      <LoaderCircle className="animate-spin" />
    ) : (
      'Proceed to Secure Checkout'
    )}
  </Button>
);

const DeliveryOption = ({
  title,
  subtitle1,
  subtitle2,
  price,
  onClick,
  checked,
}: {
  title: string;
  subtitle1: string;
  subtitle2?: string;
  price: number;
  onClick: () => void;
  checked: boolean;
}) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <div
      className={`flex items-start justify-between py-2 gap-4 rounded-lg cursor-pointer`}
      onClick={handleClick}
    >
      <div className="flex items-start gap-2">
        <div className="min-w-6">{checked ? <CircleCheck /> : <Circle />}</div>
        <div>
          <p>{title}</p>
          <p className="text-sm text-muted-foreground">{subtitle1}</p>
          <p className="text-sm text-muted-foreground">{subtitle2}</p>
        </div>
      </div>
      <p className="whitespace-nowrap">
        {currencySymbol} {(price / 100).toFixed(2)}
      </p>
    </div>
  );
};

const formatWorkingDaysRange = (startDays: number, endDays: number): string => {
  const startDate = addBusinessDays(new Date(), startDays);
  const endDate = addBusinessDays(new Date(), endDays);

  const formattedStartDate = format(startDate, 'd MMMM');
  const formattedEndDate = format(endDate, 'd MMMM');

  return `${formattedStartDate} - ${formattedEndDate}`;
};

const HowToOrderMultipleBooksDialog = () => (
  <Dialog buttonTitle="Need another book with 30% off?">
    <div className="grid gap-4">
      <div className="space-y-4">
        <DialogTitle>How to Order More Books</DialogTitle>
        <p>You can get 30% off more books once you've completed this order.</p>
        <p className="font-semibold">Here's how it works</p>
        <ul className="list-disc pl-5 leading-tight flex flex-col gap-3">
          <li className="">
            We'll send you a discount code by email as soon as we receive your
            payment.
          </li>
          <li>The discount code is valid for 24 hours.</li>
          <li>You'll need to use it during checkout for your next order.</li>
          <li>All your books will be shipped together.</li>
        </ul>
        <p>It's as simple as that - happy reading!</p>
      </div>
    </div>
  </Dialog>
);
